import React from 'react';

const UxUiHandsOnApproach = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M43.1198 35.6459C43.0307 35.6477 42.9423 35.6312 42.8599 35.5969C42.7775 35.5626 42.7032 35.5115 42.6412 35.4468L35.4024 28.4441H33.2899C33.1032 28.4441 32.9241 28.3691 32.7921 28.2356C32.66 28.1022 32.5858 27.9212 32.5858 27.7325C32.5928 27.546 32.6694 27.3693 32.8 27.2373C32.9306 27.1054 33.1054 27.0279 33.2899 27.0209H35.684C35.8612 27.0309 36.03 27.1013 36.1629 27.2203L42.4157 33.2834V26.9072C42.4135 26.7367 42.4721 26.5708 42.5807 26.4402C42.6893 26.3096 42.8408 26.2229 43.0074 26.1956C43.5384 26.1505 44.0546 25.9963 44.5247 25.7425C44.9947 25.4887 45.4085 25.1406 45.7408 24.7195C46.073 24.2985 46.3167 23.8134 46.4569 23.2938C46.5972 22.7743 46.631 22.2311 46.5563 21.6979V9.00253C46.5563 6.49759 45.5141 5.47287 42.951 5.47287H25.1217C22.5868 5.47287 20.0521 6.55452 20.0521 9.00253V21.6979C20.1327 23.0495 20.6873 24.3281 21.6165 25.3036C22.5457 26.2792 23.7884 26.8879 25.1217 27.0209H29.1778C29.3596 27.0282 29.5314 27.1064 29.6574 27.2391C29.7835 27.3717 29.8539 27.5486 29.8538 27.7325C29.8539 27.9164 29.7835 28.0932 29.6574 28.2259C29.5314 28.3586 29.3596 28.4368 29.1778 28.4441H25.1217C23.4193 28.296 21.827 27.5322 20.6369 26.2929C19.4469 25.0536 18.7392 23.4221 18.6438 21.6979V9.00253C18.6438 5.55824 21.9108 4.04961 25.1217 4.04961H42.951C43.6228 3.94726 44.3089 4.00375 44.9555 4.21466C45.6021 4.42557 46.1915 4.7851 46.6773 5.26508C47.1632 5.74507 47.5322 6.33242 47.7556 6.98091C47.9789 7.62941 48.0506 8.3214 47.9646 9.00253V21.6979C48.0891 22.9955 47.7353 24.2941 46.9713 25.3446C46.2073 26.3951 45.0866 27.1236 43.8239 27.3909V34.8488C43.8244 34.9877 43.7849 35.1237 43.7098 35.24C43.6347 35.3564 43.5277 35.4479 43.4017 35.5034C43.3252 35.5799 43.2263 35.6299 43.1198 35.6459Z"
      fill="#475467"
    />
    <path
      d="M4.86668 44.1236H4.55689C4.43392 44.0668 4.33047 43.9742 4.26017 43.8574C4.18988 43.7407 4.15594 43.6054 4.16253 43.469V36.0111C2.90289 35.7381 1.78606 35.008 1.02309 33.9589C0.260125 32.9098 -0.095975 31.6145 0.0222244 30.3181V17.5942C0.0222244 14.093 3.14856 12.5273 6.27497 12.5273H15.7105C15.8972 12.5273 16.0763 12.6024 16.2083 12.7358C16.3404 12.8693 16.4146 13.0502 16.4146 13.239C16.4146 13.4277 16.3404 13.6087 16.2083 13.7421C16.0763 13.8756 15.8972 13.9506 15.7105 13.9506H6.27497C4.0217 13.9506 1.43052 14.9185 1.43052 17.5942V30.3181C1.35581 30.8513 1.38966 31.3945 1.52988 31.914C1.6701 32.4336 1.91382 32.9187 2.24607 33.3397C2.57831 33.7608 2.99181 34.1089 3.46182 34.3627C3.93184 34.6165 4.44843 34.7704 4.97945 34.8154C5.14133 34.8419 5.28887 34.9246 5.39685 35.0493C5.50483 35.174 5.56637 35.3329 5.57082 35.4986V42.0457L13.0912 35.698C13.2142 35.5847 13.3753 35.5234 13.5417 35.5271H24.0757C25.3755 35.3986 26.5861 34.8015 27.486 33.8449C28.386 32.8884 28.9154 31.6365 28.9769 30.3181V17.5942C28.9769 15.0893 26.5261 13.9506 24.0757 13.9506H19.8508C19.664 13.9506 19.485 13.8756 19.3529 13.7421C19.2209 13.6087 19.1466 13.4277 19.1466 13.239C19.1466 13.0502 19.2209 12.8693 19.3529 12.7358C19.485 12.6024 19.664 12.5273 19.8508 12.5273H24.0757C27.2303 12.5273 30.3851 14.093 30.3851 17.5942V30.3181C30.3335 32.0171 29.6595 33.6363 28.4938 34.8617C27.3281 36.087 25.7535 36.8312 24.0757 36.9503H13.7954L5.34562 44.0951C5.19323 44.1566 5.02514 44.1666 4.86668 44.1236Z"
      fill="#475467"
    />
  </svg>
);

export default UxUiHandsOnApproach;
